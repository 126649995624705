<template>
	<vvp v-slot="v" :rules="!notRules ? rules : ''" :vid="vid" :name="vidName ?? vid" mode="eager">
		{{ messageChange(v) }}
		{{ setFailedRules(v) }}
		<select class="form-control" v-model="value" :id="v.ariaMsg.id" :name="vidName ?? vid" :ref="keyword" :disabled="disabled">
			<option v-for="(data, index) in selectOption" :key="index" :value="data[setValueCode]">
				{{ data[setValueCodeName] }}
			</option>
		</select>

		<!-- <span v-for="(error, i) in v.errors" :style="{ color: '#ff0000' }" :key="`error${i}`">{{ error }}<br/></span> -->
		<span v-if="v.errors[0] && NonStatus === false && forceErrorHandle === false" :style="{ color: '#ff0000' }">{{ v.errors[0] }}</span>
	</vvp>
</template>

<script>
	export default {
		name: "ValidInput",
		data() {
			return {
				isERROR: false,
				failedRules: null,
			};
		},
		props: {
      notRules: {
        type: Boolean,
        default: false,
      },
      vidName: {
        type:String,
        default: null
      },
			setValueCodeName: {
				require: true,
				type: String,
				default: "",
			},
			setValueCode: {
				require: true,
				type: String,
				default: "",
			},
			selectOption: {
				type: Array,
				default: () => [],
			},
			componentType: {
				type: String,
				default: "input",
			},
      NonStatus:{
        type: Boolean,
        default: false,
      },
			/** input 클래스 명 */
			classList: {
				type: String,
				default: "",
			},
			/** input 값 */
			inputValue: {
				require: true,
				type: [String, Number, null],
				default: null,
			},
			/** validation id 값 */
			vid: {
				type: String,
				default: "",
			},
			/** validation 체크 룰 설정 */
			rules: {
				required: true,
				type: String,
				default: "",
			},
			/** 에러 메세지와 상관없이 error 테두리 변경 */
			forceErrorHandle: {
				type: Boolean,
				default: false,
			},
			/** 에러발생시 출력할 메세지 */
			errorMessage: {
				type: String,
				default: "",
			},
			/** ref 주소 값 */
			keyword: {
				type: String,
				default: "",
			},
			/** disabled 여부 */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** input readonly 여부 */
			readonly: {
				type: Boolean,
				default: false,
			},
			/** input tag name 값 */
			name: {
				type: String,
				default: "",
			},
		},
		watch: {},
		computed: {
			value: {
				get() {
					return this.inputValue;
				},
				set(value) {
					this.$emit("update:inputValue", value);
				},
			},

			maxLengthValue() {
				let length = this.rules.includes("max:") ? (this.rules.split("max:")[1].includes("|") ? Number(this.rules.split("max:")[1].split("|")[0]) : Number(this.rules.split("max:")[1])) : null;

				return length;
			},

			minNumber() {
				let num = this.rules.includes("min_value:")
					? this.rules.split("min_value:")[1].includes("|")
						? Number(this.rules.split("min_value:")[1].split("|")[0])
						: Number(this.rules.split("min_value:")[1])
					: null;

				return num;
			},

			maxNumber() {
				let num = this.rules.includes("max_value:")
					? this.rules.split("max_value:")[1].includes("|")
						? Number(this.rules.split("max_value:")[1].split("|")[0])
						: Number(this.rules.split("max_value:")[1])
					: null;

				return num;
			},
		},
		mounted() {
			// console.log(this);
		},
		methods: {
			numberFormatter(value) {
				let format = value;

				if (value.length > this.maxLengthValue) {
					format = value.substr(0, this.maxLengthValue);
				}

				return format;
			},
			setFailedRules(v) {
				this.failedRules = v.failedRules;
			},
			messageChange(v) {
				let messageKey = Object.keys(v.failedRules);
				if (messageKey.length === 0) {
					v.errors[0] = "";
				} else {
					let message = v.failedRules[messageKey[0]];

					v.errors[0] = message;

					if (message.includes("is not valid") || message.includes("이 항목은 필수")) {
						v.errors[0] = this.errorMessage;
					}
				}

				// console.log(v.errors[0]);
				// if (v.errors[0]) {
				// 	let messageKey = Object.keys(v.failedRules);

				// 	for (let i = 0; i < messageKey.length; i++) {
				// 		v.errors[0] = this.errorMessage;

				// 		break;
				// 	}
				// } else {
				// 	v.errors[0] = "";
				// }
			},
			enterEvents(value) {
				this.$emit("keydownMethod", value);
			},

			changeEvents(value) {
				this.$emit("changeMethod", value);
			},

			inputEvents(value) {
				if (this.inputType === "number") {
					if (Number(value.length) > Number(this.maxLengthValue)) {
						this.$emit("update:inputValue", value.substring(0, this.maxLengthValue));
					}
				}

				this.$emit("inputMethod", value);
			},
			statusHandle(error) {
				if (this.NonStatus === true) {
					this.isERROR = false;
					return null;
				} else {
					if (error[0] !== "") {
						this.isERROR = true;
						return false;
					}
					this.isERROR = false;
					return null;
				}
			},
		},
	};
</script>

<style></style>
